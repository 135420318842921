export default {
  items: [
    // {
    //   name: 'Dashboard',
    //   url: '/dashboard',
    //   icon: 'icon-speedometer',
    //   badge: {
    //     variant: 'primary',
    //     text: 'NEW'
    //   }
    // },
    {
      title: true,
      url: '/',
      name: 'GOODS',
      role: 'CONFIRMED_R',
    },
    {
      name: '상품등록 및 수정',
      url: '/processing',
      icon: 'fa fa-tags',
      role: 'GOODS_W',
    },
    {
      name: '등록상품조회',
      url: '/goods',
      icon: 'fa fa-certificate',
      role: 'CONFIRMED_R',
    },
    {
      name: 'Mapped 조회',
      url: '/mapped',
      icon: 'fa fa-map',
      role: 'GOODS_R',
    },
    // {
    //   name: '종료상품조회',
    //   url: '/terminated',
    //   icon: 'fa fa-certificate',
    //   role: 'CONFIRMED_R',
    // },
    {
      name: '상품정보개선',
      url: '/goodsInfo',
      icon: 'fa fa-magic',
      role: 'CONFIRMED_R',
    },
    {
      name: '상품 건전성 관리',
      url: '/goodsIntegrity',
      icon: 'fa fa-ban',
      role: 'FWORD_W',
    },
    {
      name: '검색엔진 조회',
      url: '/ES',
      icon: 'fa fa-search',
      role: 'GOODS_R',
    },
    {
      name: 'Naver EP 관리',
      url: '/naverEP',
      icon: 'fa fa-edge',
      role: 'SKU_OUTSOURCE',
    },
    {
      name: 'Shopify 상품관리',
      url: '/shopify',
      icon: 'fa fa-shopping-bag',
      role: 'GOODS_R',
    },
    {
      name: 'Shein 상품관리',
      url: '/shein',
      icon: 'fa fa-shopping-bag',
      role: 'GOODS_R',
    },
    // {
    //   name: 'EP 이미지 관리',
    //   url: '/epImage',
    //   icon: 'fa fa-file-image-o',
    //   role: 'SKU_OUTSOURCE',
    // },
    // {
    //   name: '할인율 조회',
    //   url: '/discountRate',
    //   icon: 'fa fa-percent',
    //   role: 'GOODS_R',
    // },

    // MASTER START
    {
      title: true,
      url: '/',
      name: 'MASTER SKU',
      role: 'SKU_OUTSOURCE',
    },
    {
      name: '마스터SKU 생성/조회',
      url: '/master/list',
      icon: 'fa fa-search-plus',
      role: 'SKU_OUTSOURCE',
    },
    {
      name: '마스터SKU 매칭',
      url: '/master/matching',
      icon: 'fa fa-puzzle-piece',
      role: 'SKU_OUTSOURCE',
    },
    {
      name: '가격비교 (상품별)',
      url: '/master/goodsGroup',
      icon: 'fa fa-won',
      role: 'SKU_OUTSOURCE',
    },
    {
      name: '가격비교 (파트너별)',
      url: '/master/orderStat',
      icon: 'fa fa-won',
      role: 'SKU_OUTSOURCE',
    },
    {
      name: '카탈로그 매칭 수정요청',
      url: '/master/matchingRequest',
      class: 'ls-n07',
      icon: 'fa fa-pencil',
      role: 'SKU_OUTSOURCE',
    },
    {
      name: '통합 SKU 관리',
      url: '/master/skuSettings',
      icon: 'fa fa-database',
      role: 'SKU_OUTSOURCE',
    },
    // MASTER END
    {
      title: true,
      name: 'SHOP',
      role: 'PARTNER_R',
    },
    {
      name: 'SHOP 정보',
      url: '/shop',
      icon: 'fa fa-hashtag',
      role: 'PARTNER_R',
    },
    // {
    //   name: '파트너 등급 관리',
    //   url: '/partner/grade',
    //   icon: 'fa fa-hashtag',
    //   role: 'DEV',
    // },
    {
      title: true,
      name: 'PRICE',
      role: 'PRICE_R',
    },
    {
      name: '상품별 정액 할인',
      url: '/priceLimit',
      icon: 'fa fa-percent',
      role: 'PRICE_R',
    },
    // 가격 복잡성을 줄이기 위해 할인율 설정 비활성화
    // {
    //   name: '할인율 설정',
    //   url: '/discount',
    //   icon: 'fa fa-percent',
    //   role: 'PRICE_R',
    // },
    {
      name: '가격 갱신',
      url: '/renewPrice',
      icon: 'fa fa-krw',
      role: 'PRICE_X',
    },
    {
      name: '병행가격설정',
      url: '/priceRebate',
      icon: 'fa fa-cubes',
      role: 'PRICE_R',
    },

    // 가격 복잡성을 줄이기 위해 마진설정 비활성화
    // {
    //   name: '마진 설정',
    //   url: '/margin',
    //   icon: 'fa fa-krw',
    //   role: 'PRICE_R',
    // },
    // {
    //   name: '프로모션',
    //   url: '/promotion',
    //   icon: 'fa fa-cubes',
    //   role: 'PRICE_R',
    // },
    // {
    //   name: '공헌이익 조회',
    //   url: '/goodsMargin',
    //   icon: 'fa fa-cubes',
    //   role: 'PRICE_R',
    // },
    // {
    //   name: 'SHOP 프로모션',
    //   url: '/shopPromotion',
    //   icon: 'fa fa-cubes',
    //   role: 'PRICE_R',
    // },
    {
      title: true,
      name: 'ORDER',
      role: ['DELI_R','WAREHOUSE_R'],
    },
    {
      name: '고객보상현황',
      url: '/reward',
      icon: 'icon-credit-card',
      role: 'REWARD_R',
    },
    {
      name: '고객보상 제외 설정',
      url: '/rewardSetting',
      icon: 'fa fa-hashtag',
      role: 'PARTNER_R',
    },
    {
      name: '주문/반품 통계',
      url: '/deliveryStat',
      icon: 'fa fa-bar-chart',
      role: 'ORDER_R',
      private: true,
    },
    {
      name: '주문관리',
      url: '/order',
      icon: 'fa fa-shopping-bag',
      role: 'DELI_R',
      private: true,
    },
    {
      name: 'API 주문',
      url: '/apiOrder',
      icon: 'fa fa-file-text-o',
      role: 'ORDER_X',
      private: true,
    },
    {
      name: 'API 주문결과',
      url: '/apiOrderResult',
      icon: 'fa fa-file-text-o',
      role: 'ORDER_R',
      private: true,
    },
    {
      name: '주문통계',
      url: '/orderStat',
      icon: 'fa fa-bar-chart',
      role: ['BALAANEER', 'ORDER_R'],
    },
    {
      name: '기간매출통계',
      url: '/godoOrderStat',
      icon: 'fa fa-bar-chart',
      role: 'MARKETING_EXT',
    },
    {
      name: 'RPA 주문',
      url: '/rpaOrder',
      icon: 'fa fa-reddit-alien',
      role: 'ORDER_X',
      private: true,
    },
    {
      title: true,
      name: 'DATA',
      role: ['BALAANEER', 'MARKETING_EXT', 'GOODS_R', 'E_GOODS_R'],
    },
    {
      name: 'KR 대시보드',
      url: '/data/kr',
      icon: 'fa fa-line-chart',
      role: 'BALAANEER',
    },
    {
      name: 'Data Store',
      url: '/data/store',
      icon: 'fa fa-database',
      role: 'BALAANEER',
      private: true,
    },
    {
      name: 'Data Menu',
      url: '/data/menu',
      icon: 'fa fa-sitemap',
      role: ['BALAANEER', 'MARKETING_EXT', 'E_GOODS_R'],
      private: true,
    },
    {
      name: '지표 정의',
      url: '/data/indicator',
      icon: 'fa fa-file-text-o',
      role: 'BALAANEER',
    },
    {
      name: '상품통계',
      url: '/data/goodsStat',
      icon: 'fa fa-bar-chart',
      role: ['GOODS_R', 'MARKETING_EXT'],
    },
    // {
    //   name: 'Naver 최저가 히트맵',
    //   url: '/data/naver',
    //   icon: 'fa fa-file-text-o',
    //   role: 'BALAANEER',
    // },
    {
      title: true,
      name: 'AD',
      role: 'AD_R',
    },
    {
      name: '광고 리스트/충전',
      url: '/ad/list',
      icon: 'fa fa-tv',
      role: 'AD_R',
    },
    {
      title: true,
      name: 'META',
      role: 'META_W',
    },
    {
      name: '마스터 메타 관리',
      url: '/meta/master',
      icon: 'fa fa-puzzle-piece',
      role: 'META_W',
    },
    {
      name: '브랜드 관리',
      url: '/meta/brand',
      icon: 'fa fa-star',
      role: 'META_W',
    },
    // {
    //   name: '브랜드 분류',
    //   url: '/meta/brandMapping',
    //   icon: 'fa fa-file-text-o',
    //   role: 'META_W',
    // },
    {
      name: '카테고리 관리',
      url: '/meta/category',
      icon: 'fa fa-sitemap',
      role: 'META_W',
    },
    {
      name: '색 분류',
      url: '/meta/color',
      icon: 'fa fa-eyedropper',
      role: 'META_W',
    },
    {
      name: '원산지 분류',
      url: '/meta/originMapping',
      icon: 'fa fa-ship',
      role: 'META_W',
    },
    {
      name: '검색 키워드 관리',
      url: '/meta/searchKeyword',
      icon: 'fa fa-search',
      role: 'ES_KEYWORD_R',
    },
    {
      name: '발란 옵션 관리',
      url: '/meta/option',
      icon: 'fa fa-search',
      role: 'META_W',
    },
    // {
    //   title: true,
    //   name: '외부몰',
    //   role: 'EXT_GOODS_R',
    // },
    // {
    //   name: '외부몰 상품등록',
    //   url: '/ss/productRegist',
    //   icon: 'fa fa-cubes',
    //   role: 'EXT_GOODS_R',
    // },
    // {
    //   name: 'SS 등록상품조회',
    //   url: '/ss/productList',
    //   icon: 'fa fa-search',
    //   role: 'EXT_GOODS_R',
    // },
    // {
    //   name: 'SS 주문조회',
    //   url: '/ss/orderList',
    //   icon: 'fa fa-list-alt',
    //   role: 'EXT_GOODS_R',
    // },
    // {
    //   name: 'SS 할인관리',
    //   url: '/ss/discount',
    //   icon: 'fa fa-clock-o',
    //   role: 'EXT_GOODS_R',
    // },
    // {
    //   name: '외부몰 에러로그',
    //   url: '/ss/errorLog',
    //   icon: 'fa fa-list',
    //   role: 'EXT_GOODS_R',
    // },

    // {
    //   title: true,
    //   name: 'CUSTOMER',
    //   role: 'CUSTOMER_R',
    // },
    // {
    //   name: '개인정보 유출내역',
    //   url: '/customer/privacy',
    //   icon: 'fa fa-list',
    //   role: 'CUSTOMER_R',
    // },

    {
      title: true,
      name: 'DEV',
      role: 'DEV',
    },
    // {
    //   name: 'Global Log',
    //   url: '/dev/glog',
    //   icon: 'fa fa-list',
    //   role: 'DEV',
    // },
    // {
    //   name: 'Hub Godo Sync',
    //   url: '/dev/hgsync',
    //   icon: 'fa fa-list',
    //   role: 'ADMIN',
    // },
    // {
    //   name: 'Hub Godo Shop Diff',
    //   url: '/dev/hgsdiff',
    //   icon: 'fa fa-list',
    //   role: 'DEV',
    // },
    {
      name: 'Queue 현황',
      url: '/dev/queue',
      icon: 'fa fa-list',
      role: 'DEV',
    },
    {
      name: 'DB 테이블 및 컬럼',
      url: '/dev/tableWiki',
      icon: 'fa fa-database',
      role: 'DEV',
      private: true,
    },
    {
      name: 'Feed Shop 통계',
      url: '/dev/shopStat',
      icon: 'fa fa-list',
      role: 'DEV',
    },

    // 개발이 무산된 페이지
    // {
    //   name: '이미지 관리',
    //   url: '/imageManage',
    //   icon: 'fa fa-film',
    //   role: 'DEV',
    // },

    // {
    //   title: true,
    //   name: 'GODO',
    //   role: 'REVIEW_R',
    // },
    // {
    //   name: '리뷰 관리',
    //   url: '/review',
    //   icon: 'fa fa-link',
    //   role: 'REVIEW_R',
    // },  // 발란몰 어드민 이전 2023/05/03
    // {
    //   name: '상품문의 관리',
    //   url: '/qnaGoods',
    //   icon: 'icon-note',
    //   role: 'QNA_R',
    // },
    // {
    //   name: '1:1 문의 유형 관리',
    //   url: '/qnaType',
    //   icon: 'icon-note',
    //   role: 'QNA_R',
    // },
    // {
    //   name: '1:1 문의 관리',
    //   url: '/qna',
    //   icon: 'icon-note',
    //   role: 'QNA_R',
    // },
    // {
    //   name: '메인 홈 관리',
    //   url: '/main_home',
    //   icon: 'fa fa-link',
    //   role: 'GODO_R',
    // }, // 발란몰 어드민 이전 2023/04/06
    // {
    //   name: '카테고리 홈 관리',
    //   url: '/category_home',
    //   icon: 'fa fa-link',
    //   role: 'GODO_R',
    // }, // 발란몰 어드민 이전 2023/04/14
    // {
    //   name: '팝업 관리',
    //   url: '/popup',
    //   icon: 'fa fa-link',
    //   role: 'GODO_R',
    // }, // 발란몰 어드민 이전 2023/04/28
    // {
    //   name: '타임세일 관리',
    //   url: '/timesale',
    //   icon: 'fa fa-clock-o',
    //   role: 'GODO_R',
    // },
    // {
    //   name: '상시 기획전 관리',
    //   url: '/sale_drop_banner_sets',
    //   icon: 'fa fa-link',
    //   role: 'GODO_R',
    // },
    // {
    //   name: '특가 상품 관리',
    //   url: '/sale_drop_goods_sets',
    //   icon: 'fa fa-clock-o',
    //   role: 'GODO_R',
    // }, // 발란몰 어드민 이전 2023/04/28
    // {
    //   name: '이벤트 관리',
    //   url: '/event_list',
    //   icon: 'fa fa-gift',
    //   role: 'GODO_R',
    // },
    // {
    //   name: '기획전',
    //   url: '/event',
    //   icon: 'fa fa-gift',
    //   role: 'GODO_R',
    // },
    // {
    //   name: '구좌별 배너 관리',
    //   url: '/banner_page_list',
    //   icon: 'fa fa-film',
    //   role: 'GODO_R',
    // }, // 발란몰 어드민 이전 2023/04/28
    // {
    //   name: 'EP 썸네일 순서 설정',
    //   url: '/naver_thumbnail_order',
    //   icon: 'fa fa-sort',
    //   role: 'GODO_R',
    // },
    // {
    //   name: 'EP 썸네일 크롭 설정',
    //   url: '/naver_thumbnail_crop',
    //   icon: 'fa fa-expand',
    //   role: 'GODO_R',
    // },
/*    {
      name: '고객별구매내역',
      url: '/customer/list',
      icon: 'fa fa-list',
      role: 'GODO_R',
    },*/

    // {
    //   title: true,
    //   name: 'Settings',
    //   role: 'DEV',
    // },
    // {
    //   name: '링크모음',
    //   url: '/link',
    //   icon: 'fa fa-link',
    //   role: 'DEV',
    // },
    // {
    //   name: 'Settings',
    //   url: '/settings',
    //   icon: 'fa fa-gear',
    //   role: 'DEV',
    // },
  ]
}
